import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    credentials: {},
    event_id: document.querySelector('#eventId').innerText.trim().includes('{{')
      ? '35391962'
      : document.querySelector('#eventId').innerText.trim(),
    landing_uuid: document.querySelector('#landing_uuid').innerText.trim().includes('{{')
      ? 'd7aab0d3-db92-4845-a9c0-7546827d60bc'
      : document.querySelector('#landing_uuid').innerText.trim(),
    cloaking: (() => {
      const cloak = document.querySelector('#cloaking').innerHTML.trim();
      document.querySelector('#cloaking').remove();
      return cloak.includes('if') ? 'true' : cloak;
    })(),
  },
  mutations: {
    setCredentials(state, value) {
      state.credentials = value;
    },
  },
  actions: {
    clickHouseEvent(context, eventObj) {
      const formData = new FormData();
      formData.append('event_id', context.state.event_id);
      formData.append('event_type', eventObj.event_type);
      if (eventObj.value) {
        formData.append('value', eventObj.value);
      }
      return fetch('https://event.fx2.io/store',
        {
          method: 'POST',
          body: formData,
        });
    },
    getProducts(context, exceptProduct) {
      const formData = new FormData();
      formData.append('event_id', context.state.event_id);
      formData.append('except_subgroup', exceptProduct);
      return fetch('https://web2app.fx2.io/payment/form/products',
        {
          method: 'POST',
          body: formData,
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data);
    },
    getCredentials(context, { login, password }) {
      const formData = new FormData();
      formData.append('email', login);
      formData.append('password', password);
      return fetch('https://expertchat.mathmaster.me/api/user/auth', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
    checkTransactionStatus(context, orderUuid) {
      const formData = new FormData();
      formData.append('order_uuid', orderUuid);
      return fetch('https://web2app.fx2.io/order/status', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
    upSale(context, orderUuid) {
      const formData = new FormData();
      formData.append('order_uuid', orderUuid);
      formData.append('product_uuid', '43f46d73-8efe-448a-b9f4-3561f2b1e855');
      return fetch('https://web2app.fx2.io/paymentflow/upsell/store', {
        method: 'POST',
        body: formData,
      })
        .then((data) => data.json());
    },
    receiveReferralCode(context) {
      const formData = new FormData();
      formData.append('referral', window.sessionStorage.getItem('referralCode'));
      return fetch('https://expertchat.fx2.io/api/billing/referral', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: context.state.credentials.uuid,
        },
        body: formData,
      })
        .then((data) => data.json());
    },
  },
  modules: {},
});
