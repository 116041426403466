import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/offer',
    name: 'Offer',
    component: () => import(/* webpackChunkName: "plan" */ '../views/Plans.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "success" */ '../views/Success.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "other" */ '../views/Terms.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "other" */ '../views/Privacy.vue'),
  },
  {
    path: '/money-back',
    name: 'Money Back',
    component: () => import(/* webpackChunkName: "other" */ '../views/MoneyBack.vue'),
  },
  {
    path: '/sub-terms',
    name: 'Subscription terms',
    component: () => import(/* webpackChunkName: "other" */ '../views/SubTerms.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
