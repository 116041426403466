<template>
  <div class="mail h-100">
    <div class="mail-content h-100 d-flex flex-column">
      <div class="header">
        <div class="container">
          <div
            class="d-flex justify-content-start justify-content-md-between align-items-center">
            <div class="header-logo d-flex align-items-center">
              <img src="../assets/icons/Logo.svg" alt="">
              <span class="ff-proxima fs-5 fw-bold text-primary text-uppercase me-2">
                Math master
              </span>
            </div>
            <div class="header-nav d-none d-md-flex align-items-center">
              <router-link
                to="/privacy"
                class="btn btn-link text-primary text-decoration-none fw-bold text-uppercase me-4">
                privacy
              </router-link>
              <router-link
                to="/terms"
                class="btn btn-link text-primary text-decoration-none fw-bold text-uppercase">
                terms
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="mail-body">
        <div class="container">
          <div class="d-flex justify-content-center mt-4 mail-ill">
            <img src="../assets/tutor-illustrations/mail_ill.svg" alt="">
          </div>
          <p class="mail-congrats fs-5 text-center mb-0 mb-md-2">
            Congrats!
          </p>
          <h1 class="mail-title text-center">
            You were invited to Mathmaster
          </h1>
          <p class="mail-sign text-center">
            Sign up now to get
            <span class="text-primary">
            5 FREE SOLUTIONS
          </span>.
            <br>
            Your friend also gets 5 FREE SOLUTIONS.
          </p>
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
              <div
                class="mail-form d-flex flex-column flex-md-row
                align-items-stretch align-items-md-start mb-4 mb-5 position-relative">
                <img src="../assets/tutor-illustrations/vertical-arrow.svg"
                     class="position-absolute d-none d-md-inline-block mail-arrow"
                     alt="">
                <img src="../assets/tutor-illustrations/Sparkle.svg"
                     class="position-absolute d-none d-md-inline-block mail-sparkle"
                     alt="">
                <div class="w-100 mb-3 mb-md-0">
                  <input
                    class="form-control form-control-lg mail-field"
                    type="email"
                    ref="emailInput"
                    id="email"
                    @keyup.enter="enterPress"
                    @input="validateEmail($event.target.value)"
                    placeholder="Email address"
                    aria-label="put your email address">
                  <div ref="alert" class="invalid-feedback fade hide">
                    Enter your email please
                  </div>
                </div>
                <button
                  type="button"
                  ref="sendBtn"
                  @click="sendEmail()"
                  class="btn btn-lg btn-primary mail-btn ms-md-3 text-uppercase disabled">
                  Sign&nbsp;up&nbsp;now
                </button>
              </div>
              <p class="mail-text text-center ff-proxima">
                By continuing, you indicate that you've read and agree to our
                <router-link to="/terms">
                  Terms & Conditions
                </router-link>
                and
                <router-link to="/privacy">
                  Privacy Policy
                </router-link>
                and give your permission to send you an email with your login details
                and notify about your math tasks done.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer bg-primary">
      <div class="container">
        <div class="row mb-4">
          <div
            class="
            offset-0 offset-md-2
            col-12 col-md-8
            d-flex flex-column flex-md-row
            align-items-center
            justify-content-lg-between">
            <a
              href="#"
              class="footer-link btn btn-link text-decoration-none text-uppercase text-white">
              about
            </a>
            <a
              href="mailto:support@mathmaster.me"
              class="footer-link btn btn-link text-decoration-none text-uppercase text-white"
            >
              support
            </a>
            <router-link
              to="/privacy"
              class="footer-link btn btn-link text-decoration-none text-uppercase text-white">
              privacy and policy
            </router-link>
            <router-link
              to="/terms"
              class="footer-link btn btn-link text-decoration-none text-uppercase text-white">
              Terms AND CONDITIONS
            </router-link>
          </div>
          <div
            class="col d-flex justify-content-md-end align-items-center justify-content-center">
            <a href="#" class="d-flex me-0 me-md-4">
              <img src="../assets/icons/Facebook.svg" alt="">
            </a>
            <a href="#" class="d-flex">
              <img src="../assets/icons/Instagram.svg" alt="">
            </a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10 offset-md-2 col-md-10">
            <p class="footer-text text-center text-md-start text-uppercase">
              © 2021 Starnest Services Ltd, Limassol, Cyprus. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getCookie, setCookie } from '@/main';

export default {
  name: 'Mail',
  methods: {
    validateEmail(inputValue) {
      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email)
          .toLowerCase());
      }
      if (validateEmail(inputValue?.trim())) {
        this.$refs.emailInput.classList.remove('is-invalid');
        this.$refs.sendBtn.classList.remove('disabled');
        this.$refs.alert.classList.replace('show', 'hide');
        return 'valid';
      }
      this.$refs.emailInput.classList.add('is-invalid');
      this.$refs.sendBtn.classList.add('disabled');
      this.$refs.alert.classList.replace('hide', 'show');
      return 'invalid';
    },
    sendEmail() {
      if (this.$refs.emailInput.value?.trim()) {
        window.gtag('set', 'user_data', {
          email: this.$refs.emailInput.value?.trim(),
        });
        window.localStorage.setItem('email', this.$refs.emailInput.value?.trim());
        this.$store.dispatch('clickHouseEvent', {
          event_type: 'store_email',
          value: this.$refs.emailInput.value?.trim(),
        });
        this.$router.push('/offer')
          .catch(() => {
          });
      }
    },
    enterPress() {
      if (this.validateEmail(this.$refs.emailInput.value) === 'valid') {
        this.sendEmail();
      }
    },
  },
  mounted() {
    if (!getCookie('planchik')) {
      this.$store.dispatch('getProducts')
        .then((data) => {
          setCookie('planchik', JSON.stringify(data.products));
          if (!getCookie('special_plan')) {
            this.$store.dispatch('getProducts',
              JSON.parse(getCookie('planchik')).before_timer.subgroup)
              .then((specData) => {
                setCookie('special_plan', JSON.stringify(specData.products));
              });
          }
        });
    }
    if (this.$route.query.referral) {
      window.sessionStorage.setItem('referralCode', this.$route.query.referral);
    }
    this.$store.dispatch('clickHouseEvent', {
      event_type: 'landing_emailpage_view',
    });
  },
};
</script>

<style lang="scss" scoped>
.mail {
  background-image: url("../assets/tutor-illustrations/Illustration_background.webp");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;

  &-ill {
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  &-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 56px;
    line-height: 60px;
    color: #1F1D2B;

    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 44px;

      margin-bottom: 16px;
    }
  }

  &-sign {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;

    margin-bottom: 48px;

    @media screen and (max-width: 768px) {
      font-size: 17px;
      line-height: 27px;

      margin-bottom: 32px;
    }
  }

  &-field {
    border-radius: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  &-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    border-radius: 16px;
    min-height: 56px;

    padding-left: 40px;
    padding-right: 40px;
  }

  &-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  &-arrow {
    right: calc(100% + 35px);
    bottom: 100%;
  }

  &-sparkle {
    left: 100%;
    bottom: 50%;
    transform: translate(-30%, 0);
  }
}

.footer {
  padding: 40px 0 24px;

  &-link {
    padding: 10px 0;
  }

  &-text {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    opacity: 0.3
  }
}

@media screen and (min-width: 500px) {
  .mail {
    background-size: cover;
  }
}

@media screen and (min-width: 720px) {
  .mail {
    background-image: url('../assets/bg/webp/BG desktop - 720.webp');
    background-size: contain;
  }
}

@media screen and (min-width: 1200px) {
  .mail {
    background-image: url('../assets/bg/webp/BG desktop - 1200.webp');
    @media screen and (max-height: 1000px) {
      background-size: cover;
    }
  }
}

@media screen and (min-width: 1920px) {
  .mail {
    background-size: cover;
  }
}
</style>
